import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ReactComponent as Eye } from '../../assets/svg/content/status-view-all.svg';
import { ReactComponent as NoEye } from '../../assets/svg/content/status-view-none.svg';
import { useOnScreen } from '../../hooks';
import Label from './Label';

interface PasswordInputProps {
    value?: string;
    name?: string;
    placeholder?: string,
    required?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    forceError?: string,
    disabled?: boolean,
    label?: string,
    className?: string,
    'data-test-id'?: string,
}

function PasswordInput({ value, name, placeholder, required, onChange, error, forceError, disabled, label, 'data-test-id': testId, className }: PasswordInputProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isVisible = useOnScreen(inputRef);
    const cls = `form__item ${className || ''}`;
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (error && !isVisible) {
            const input = inputRef.current;
            const top = input?.offsetTop || 0;
            window.scrollTo(0, top - 40);
        }
        // eslint-disable-next-line
    }, [error]);

    return (
        <>
            {label && <Label>{label}</Label>}
            <div className={cls}>
                <div style={{ display: 'inline-flex', width: 'calc(100% + 1rem)' }}>
                    <input
                        ref={inputRef}
                        type={showPassword ? 'text' : 'password'}
                        className={'form__input' + (((error && (!value && required)) || forceError) ? ' _error' : '')}
                        required={required}
                        value={value}
                        name={name}
                        placeholder={placeholder}
                        onChange={(e) => { onChange && onChange(e); }}
                        disabled={disabled}
                        data-test-id={testId}
                        style={{ paddingRight: '2rem' }}
                    />
                    <span>
                        {showPassword ?
                            <NoEye width={'16px'} height={'16px'} style={{ position: 'relative', right: '1.5rem', top: '30%' }} onClick={() => setShowPassword(!showPassword)} />
                            :
                            <Eye width={'16px'} height={'16px'} style={{ position: 'relative', right: '1.5rem', top: '30%' }} onClick={() => setShowPassword(!showPassword)} />
                        }
                    </span>
                </div>
                {(error && (!value && required)) && <span className="form__input-error-message">{error}</span>}
                {forceError && <span className="form__input-error-message">{forceError}</span>}
            </div>
        </>
    );
}

export default PasswordInput;