import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../assets/svg/ArrowDownPerm.svg';
import { ReactComponent as FileDoc } from '../assets/svg/file-doc.svg';
import { ReactComponent as HeadIcon } from '../assets/svg/permission.svg';
import { ReactComponent as Plus } from '../assets/svg/Plus.svg';
import { extractErrorDetail, useIsProvider, ymHit } from '../components/common';
import Button from '../components/mplat/Button/Button';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import Table from '../components/mplat/DataTable/Table';
import TableBody from '../components/mplat/DataTable/TableBody';
import { TableColumn } from '../components/mplat/DataTable/TableColumn';
import TableHeader from '../components/mplat/DataTable/TableHeader';
import { TableHeaderColumn } from '../components/mplat/DataTable/TableHeaderColumn';
import TableRow from '../components/mplat/DataTable/TableRow';
import { DatePicker } from '../components/mplat/DatePicker';
import Form from '../components/mplat/Form';
import Header1 from '../components/mplat/Header1';
import ImageLoader from '../components/mplat/ImageLoader';
import Label from '../components/mplat/Label';
import MainHeader from '../components/mplat/MainHeader';
import { Modal } from '../components/mplat/Modal';
import MultiSelect from '../components/mplat/MultiSelect';
import NoHideEmailAdmin from '../components/mplat/NoHide/NoHideEmailAdmin';
import NoHideFIOAdmin from '../components/mplat/NoHide/NoHideFIOAdmin';
import NoHidePhoneAdmin from '../components/mplat/NoHide/NoHidePhoneAdmin';
import { toSourceParams } from '../components/mplat/OrderContentConsumer';
import { RadioInput } from '../components/mplat/RadioInput';
import { Searcher } from '../components/mplat/Searcher';
import Select from '../components/mplat/Select';
import TextInput from '../components/mplat/TextInput';
import { SOURCE_MAP } from '../const';
import { useAuth } from '../hooks';
import { useGetXlsMutation } from '../services/api';
import {
    AccessPermission,
    AccessPermissionFull,
    AccessPermissionStatus,
    SourcesType,
    useCreateAccessPermissionMutation,
    useLazyGetAccessPermissionPdfQuery,
    useLazyGetAccessPermissionQuery,
    useLazyGetAccessPermissionsQuery,
    useLazyGetAccessRequestsQuery,
    useLazyGetTVListQuery,
    useUpdateAccessPermissionStatusMutation
} from '../services/content_v1';
import { useGetAccountListQuery, useLazyGetAccountListQuery } from '../services/users_v1';
import { toTextStatus } from './PermissionEditorPage';
import { toMplatDate } from './ToolsPage';

export const AVAIL_SOURCES = [
    { value: SourcesType.ANY, label: 'Любой' },
    { value: SourcesType.SATELLITE, label: 'Спутник' },
    { value: SourcesType.MEDIALOGISTIKA, label: 'Медиалогистика' },
    { value: SourcesType.OTHER, label: 'Другое' },
];

interface PermissionViewModalProps {
    isOpen: boolean,
    permId: number,
    onClose: () => void,
}

export function PermissionViewModal({ isOpen, permId, onClose }: PermissionViewModalProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isProvider = useIsProvider();

    const [fetchPermission, { data: permData }] = useLazyGetAccessPermissionQuery();
    const [fetchPdf] = useLazyGetAccessPermissionPdfQuery();

    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<string>();

    const [perm, setPerm] = useState<AccessPermissionFull | undefined>(undefined);

    const successClass = 'popup_message';

    useEffect(() => ymHit('/access/permissions'), []);

    useEffect(() => {
        if (isOpen) {
            fetchPermission(permId);
            setSuccess(false);
            setFail('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (permData?.results) {
            setPerm(permData?.results);
        }
    }, [permData]);

    return (
        <Modal className={success ? successClass : 'form-opinion'} isOpen={isOpen} onClose={() => onClose()}>
            {fail}
            {perm &&
                <div className="permission__channels-view">
                    {perm.status === AccessPermissionStatus.REVOKED &&
                        <p>
                            <span style={{ color: 'red' }}><Trans>Permission</Trans> ID{perm.id} <Trans>REVOKED</Trans> {perm.revoked && toMplatDate(perm.revoked, true)}</span>
                        </p>
                    }
                    {perm.status === AccessPermissionStatus.ISSUED &&
                        <p>
                            <span style={{ color: 'green' }}><Trans>Permission</Trans> ID{perm.id} <Trans>ISSUED</Trans> {perm.issued && toMplatDate(perm.issued, true)}</span>
                        </p>
                    }
                    {perm.status === AccessPermissionStatus.WORKING &&
                        <p>
                            <span style={{ color: 'gray' }}><Trans>Draft</Trans> ID{perm.id}</span>
                        </p>
                    }
                    <p>
                        {perm.issuer_accs?.length ?
                            <>
                                <strong>
                                    {perm.issuer_accs.map((acc) => acc.org.name || acc.name).join(', ')}
                                </strong>
                                {' '}
                                <Trans>order:PERMISSION_FROM_MANY_TO</Trans>
                            </>
                            :
                            <>
                                <strong>{perm.issuer.org?.name || perm.issuer.name}</strong>
                                {' '}
                                <Trans>order:PERMISSION_FROM_TO</Trans>
                            </>
                        }
                        {' '}
                        <strong>{perm.recipient.org?.name || perm.recipient.name}</strong>
                        {' '}
                        <Trans>order:PERMISSION_FROM_TO_TV</Trans>
                    </p>
                    <div className="permission__channels-view-channels">
                        <table>
                            <thead>
                                <tr>
                                    <td className="_name"><Trans>TV channel</Trans></td>
                                    <td className="_format"><Trans>order:TV_QUALITY</Trans></td>
                                    <td className="_licence"><Trans>order:TV_MEDIA_LIC</Trans></td>
                                    <td className="_comment"><Trans>order:TV_VERSION</Trans></td>
                                </tr>
                            </thead>
                            <tbody>
                                {perm.units.map((unit) => <tr key={unit.unit.id}>
                                    <td className="_name">{unit.unit.title}</td>
                                    <td className="_format">{unit.fmt.map((f) => f.name).join(', ')}</td>
                                    <td className="_licence">{unit.unit.mmcertno} {unit.unit.licno && ` · ${unit.unit.licno}`}</td>
                                    <td className="_comment">{unit.version}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <h3>
                        <Trans>order:IN_SOURCES</Trans>:
                    </h3>
                    <ul>
                        {perm.details.sources?.map((s) => <li key={s.stype}>
                            {SOURCE_MAP[s.stype]}
                            {s.satellites && <><br />{s.satellites.map((ss) => ss.name).join(', ')}</>}
                            <br />
                            {toSourceParams(s.params)}
                        </li>)}
                        {!perm.details.sources && <li><Trans>account:NOT_SPECIFIED</Trans></li>}
                    </ul>

                    {!!perm.details?.additional && <>
                        <h3><Trans>order:SPEC_CONDITIONS</Trans></h3>
                        <p>
                            {perm.details.additional}
                        </p>
                    </>}

                    {!!perm.details.licno && <>
                        <h3><Trans>order:LIC</Trans></h3>
                        <p>
                            {perm.details.licno} {perm.details.licdate ? `от ${perm.details.licdate}` : ''}
                        </p>
                    </>}

                    <h3><Trans>order:PERMISSION_PERIOD</Trans></h3>
                    <p>
                        <Trans>order:PERMISSION_PERIOD_FROM</Trans> {perm.validity.lower ? toMplatDate(perm.validity.lower, true) : t('order:PERMISSION_PERIOD_FROM_TODAY')}
                        &nbsp;
                        <Trans>order:PERMISSION_PERIOD_TO</Trans> {perm.validity.upper ? toMplatDate(perm.validity.upper, true) : t('order:PERMISSION_PERIOD_TO_REVOKE')}
                    </p>

                    <h3><Trans>order:PERMISSION_ISSUER</Trans></h3>
                    <p>
                        {/* <Link to={`/accounts/${perm.issuer.id}`} className="link-accent">
                            @{perm.issuer.nickname || perm.issuer.id}
                        </Link> */}
                        <NoHideFIOAdmin user={perm.issuerperson} />, <br />
                        <NoHidePhoneAdmin user={perm.issuerperson} />, <br />
                        <NoHideEmailAdmin user={perm.issuerperson} />
                    </p>

                    <p className="permission__channels-view-cta">
                        {isProvider && (perm.status === AccessPermissionStatus.WORKING) &&
                            <Button onClick={() => navigate(`/access/permissions/${perm.id}`)}>
                                <Trans>order:PERMISSION_EDIT</Trans>
                            </Button>
                        }
                        <Link to="" className="main__types-file" onClick={(ev) => {
                            ev.preventDefault();
                            fetchPdf(perm.id).unwrap().then((data) => {
                                saveAs(data, `permission_${perm.id}.pdf`);
                            }).catch((err) => {
                                alert(extractErrorDetail(err));
                            });
                        }}>
                            <FileDoc />
                            <Trans>order:LOAD_PDF</Trans>
                        </Link>
                    </p>
                </div>
            }
        </Modal>
    );
}

interface PermissionModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export function PermissionModalModal({ isOpen, onClose }: PermissionModalProps) {
    const { t } = useTranslation();

    const [createPermission] = useCreateAccessPermissionMutation();
    const [refetch, { data: listData }] = useLazyGetTVListQuery();
    const [refetchUsers, { data: users }] = useLazyGetAccountListQuery();
    const auth = useAuth();

    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<string>();
    const successClass = 'popup_message';

    const [haveLic, setHaveLic] = useState<boolean>(true);
    const [licNum, setLicNum] = useState('');
    const [licDate, setLicDate] = useState('');
    const [permPeriod, setPermPeriod] = useState(1);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [stopDate, setStopDate] = useState<Date | undefined>();
    const [units, setUnits] = useState<number[]>([]);
    const [qualities, setQualities] = useState<number[]>([]);
    const [source, setSource] = useState<number>(1);
    const [psdetails, setPSDetails] = useState('');
    const [recipient, setRecipient] = useState<number>(NaN);

    const availUsers = useMemo(() => {
        if (users?.results.data) {
            return users.results.data.map((u) => ({ value: u.id, label: `${u.name} [${u.id}]` }));
        }
        return [];
    }, [users]);

    const availUnits = useMemo(() => {
        if (listData?.results.data) {
            return listData.results.data.map((tv) => ({ value: tv.id, label: tv.title }));
        }
        return [];
    }, [listData]);

    const availQualities = useMemo(() => {
        if (listData?.results) {
            const tmp = listData.results.data.reduce((acc, unit) => {
                unit.qualities.forEach((q) => {
                    acc[q.id] = { value: q.id, label: q.name };
                });
                return acc;
            }, {} as Record<string, { value: number, label: string }>);
            return Object.values(tmp);
        }
        return [];
    }, [listData]);

    // const availQualities = useMemo(() => {
    //     if (refBook?.results) {
    //         return refBook.results.quality.map((ref) => ({ value: ref.id, label: `${ref.displayname}` }));
    //     }
    //     return [];
    // }, [refBook]);

    useEffect(() => {
        refetch({ offset: 0, limit: -1 });
        refetchUsers({});
        if (isOpen) {
            setSuccess(false);
            setFail('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (permPeriod === 2) {
            setStartDate(new Date())
            setStopDate(new Date(2099, 1, 1))
        }
    }, [permPeriod]);

    return (
        <Modal className={success ? successClass : 'form-opinion'} isOpen={isOpen} onClose={() => onClose()}>
            {fail}
            <Form action="#" className="form-opinion" id="provide-opinion">
                <Header1>Оформление разрешения</Header1>
                <Label><b>Кому</b></Label>
                <Select value={recipient} options={availUsers} onChange={(v) => setRecipient(v)} />
                <Label><b><Trans>order:LIC</Trans></b></Label>
                <RadioInput value={haveLic} items={[{ value: true, label: t('order:ORDER_LIC_WITH') }, { value: false, label: t('order:ORDER_LIC_WITHOUT') }]} onChange={(v) => setHaveLic(v)} />
                {haveLic && <>
                    <TextInput value={licNum} placeholder={t('order:ORDER_LIC_NO')} onChange={(e) => setLicNum(e.target.value)} />
                    <TextInput value={licDate} placeholder={t('order:ORDER_LIC_DATE')} onChange={(e) => setLicDate(e.target.value)} />
                </>}
                <Label><b><Trans>order:PERMISSION_PERIOD</Trans></b></Label>
                <Select value={permPeriod} options={[{ value: 1, label: 'Задать руками' }, { value: 2, label: 'До отзыва' }]} onChange={(v) => setPermPeriod(v)} />
                <DatePicker value={startDate} placeholder="" setValue={(v) => setStartDate(v)} />
                <DatePicker value={stopDate} placeholder="" maxDate={new Date(2100, 1, 1)} setValue={(v) => setStopDate(v)} />
                <Label><b>Телеканалы</b></Label>
                <MultiSelect value={units} options={availUnits} setValue={(v) => setUnits(v)} />
                <Label><b>Качество</b></Label>
                <MultiSelect value={qualities} options={availQualities} setValue={(v) => setQualities(v)} />
                <Label><b>Разрешенный источник</b></Label>
                <RadioInput value={source} items={AVAIL_SOURCES} onChange={(v) => setSource(v)} />
                {source === 4 && <>
                    <TextInput value={psdetails} placeholder={'Укажите параметры'} onChange={(e) => setPSDetails(e.target.value)} />
                </>}
                <div className="form__submit">
                    <Button
                        disabled={isNaN(recipient)}
                        onClick={() => {
                            createPermission({
                                issuer: auth.account,
                                recipient: recipient,
                                validity: {},
                                details: {
                                    havelic: haveLic,
                                    licno: licNum,
                                    licdate: licDate || null,
                                    ontest: null,
                                    sources: [{ stype: source, params: psdetails }],
                                },
                                units: units.map((u) => ({ fmt: qualities, unit: u, version: null })),
                            }).unwrap().then(() => {
                                setSuccess(true);
                                onClose();
                            }).catch(e => {
                                setFail(JSON.stringify(e));
                            })
                        }}
                    >Отправить разрешение</Button>
                </div>
            </Form>
        </Modal >
    );
}

interface CreateButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

function CreateButton({ onClick }: CreateButtonProps) {
    const { ref, inView } = useInView({
        threshold: 1
    });

    return (
        <div ref={ref} className={'permissions-add' + (inView ? '' : ' _fixed')}>
            <Button
                data-test-id="contentSaveButton"
                type="submit"
                onClick={(e) => onClick(e)}
            >
                <Plus /> <Trans>Permission</Trans>
            </Button>
        </div>
    );
}


interface PermissionTableRowProps {
    perm: AccessPermission,
    isProvider: boolean,
    onShowPermissionView: (permId: number) => void
}

function PermissionTableRow({ perm, isProvider, onShowPermissionView }: PermissionTableRowProps) {
    const { t } = useTranslation();

    const [active, setActive] = useState(false);
    const [h, setH] = useState(0);
    const divRef = useRef<HTMLDivElement | null>(null);

    const [fetchPdf] = useLazyGetAccessPermissionPdfQuery();
    const [updatePermissionStatus] = useUpdateAccessPermissionStatusMutation();

    useEffect(() => {
        if (divRef.current) {
            setH(divRef.current.clientHeight);
        }
    }, [divRef.current, active]);

    return (
        <>
            <TableRow key={perm.id} className={active ? '_active' : undefined} onClick={() => setActive((v) => !v)}>
                <TableColumn className="_id">
                    {perm.id}
                    {/* <Link to={`/access/permissions/${perm.id}`}>{perm.id}</Link> */}
                </TableColumn>
                <TableColumn className="_content">
                    <strong>
                        <Link to={`/accounts/view/${isProvider ? perm.recipient.id : perm.issuer.id}`} className="link-accent">
                            {isProvider ? perm.recipient.name : perm.issuer.name}
                        </Link>
                    </strong>
                </TableColumn>
                <TableColumn className="_date">{toMplatDate(perm.validity.lower, true)}</TableColumn>
                <TableColumn className="_date">{toMplatDate(perm.validity.upper, true)}</TableColumn>
                <TableColumn className="_content">{t(toTextStatus(perm.status))}</TableColumn>
                <TableColumn className="_arrow">
                    <span className="permission__arrow">
                        <Arrow />
                    </span>
                </TableColumn>
                {/* {JSON.stringify(perm)} */}
            </TableRow>
            {active && (
                <div className="permissions-list__content-cell" style={{ display: 'block', height: `${h}px` }}>
                    <div ref={divRef} className="permissions-list__content-body">
                        <div className="permissions-list__content-top">
                            <div className="permissions-list__content-info">
                                <div className="permissions-list__content-info-item">
                                    <p><strong><Trans>order:LIC</Trans></strong></p>
                                    <p>{perm.details.licno ? <div>{perm.details.licdate} · {perm.details.licno}</div> : <> Без лицензии</>}</p>
                                </div>
                                <div className="permissions-list__content-info-item">
                                    <p><strong>Разрешенный источник</strong></p>
                                    <p>{perm.details.sources?.map((ss) => AVAIL_SOURCES.find((s) => s.value === ss.stype)?.label).join(', ') || 'Нет'}</p>
                                </div>
                                <div className="permissions-list__content-info-item">
                                    <p><strong>Лицо, сделавшее запрос</strong></p>
                                    <p>
                                        <NoHideFIOAdmin user={perm.recipient.owner} />, <br />
                                        <NoHidePhoneAdmin user={perm.recipient.owner} />, <br />
                                        <NoHideEmailAdmin user={perm.recipient.owner} />
                                    </p>
                                </div>
                                <div className="permissions-list__content-info-item">
                                    <p><strong>Администратор</strong></p>
                                    <p>
                                        <NoHideFIOAdmin user={perm.issuer.owner} />, <br />
                                        <NoHidePhoneAdmin user={perm.issuer.owner} />, <br />
                                        <NoHideEmailAdmin user={perm.issuer.owner} />
                                    </p>
                                </div>
                                <div className="permissions-list__content-info-item">
                                    <p><strong>Бесплатный тест</strong></p>
                                    <p>{perm.details.ontest ? `${perm.details.ontest} дней` : 'Отсутствует'}</p>
                                </div>
                            </div>
                            <div className="permissions-list__content-actions">
                                <p>
                                    <span className="link link-accent _js-permissions-list-view" onClick={() => {
                                        onShowPermissionView(perm.id);
                                    }}>
                                        <Trans>order:PERMISSION_VIEW</Trans>
                                    </span>
                                </p>
                                <p>
                                    {perm.status === AccessPermissionStatus.ISSUED &&
                                        <Link to="" className="link-file" onClick={(ev) => {
                                            ev.preventDefault();
                                            fetchPdf(perm.id).unwrap().then((data) => {
                                                saveAs(data, `permission_${perm.id}.pdf`);
                                            }).catch((err) => {
                                                alert(extractErrorDetail(err));
                                            });
                                        }}>
                                            <FileDoc />
                                            <Trans>order:LOAD_PDF</Trans>
                                        </Link>
                                    }
                                </p>
                                <p className="permissions-list__content-actions-cancel">
                                    {isProvider && perm.status === AccessPermissionStatus.ISSUED &&
                                        <Button className="_bd" onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            const ret = confirm(t('order:PERMISSION_REVOKE_CONFIRM_MESSAGE'));
                                            if (ret) {
                                                updatePermissionStatus({
                                                    id: perm.id,
                                                    status: AccessPermissionStatus.REVOKED,
                                                    // updated: new Date().toISOString(),
                                                }).unwrap().then(() => {
                                                    // setSuccess(true);
                                                }).catch(e => {
                                                    alert(extractErrorDetail(e));
                                                });
                                            }
                                        }}>
                                            <Trans>order:PERMISSION_REVOKE</Trans>
                                        </Button>
                                    }
                                    {/* <a href="#" className="btn ">Отозвать разрешение</a> */}
                                </p>
                            </div>
                        </div>
                        <div className="permissions-list__content-channels">
                            <p><strong>Телеканалы</strong></p>
                            <div className="permissions-list__content-channels-list">
                                {perm.units.map((u) => (
                                    <div key={u.unit.id} className="permissions-list__content-channels-item">
                                        {u.unit.covers.at(0) ?
                                            <ImageLoader className="permissions-list__content-channels-item-image" url={u.unit.covers.at(0)} />
                                            :
                                            <div className="permissions-list__content-channels-item-image empty-image" />
                                        }
                                        <div className="permissions-list__content-channels-item-body">
                                            <div className="permissions-list__content-channels-item-name">{u.unit.title}</div>
                                            <div className="permissions-list__content-channels-item-type">{u.unit.category?.name}</div>
                                        </div>
                                    </div>
                                    //   {u.fmt.map((q) => q.name).join(', ')}
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const PAGE_SIZE = 24;

function PermissionsPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isProvider = useIsProvider();

    const [refetch, { data }] = useLazyGetAccessPermissionsQuery();
    const [refetchOrders, { data: dataOrders }] = useLazyGetAccessRequestsQuery();
    const { data: clients } = useGetAccountListQuery({});
    const [fetchXlsData, { data: xlsData }] = useGetXlsMutation();

    const [current, setCurrent] = useState(0);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const [ordering, setOrdering] = useState('-id');
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [showPermissionViewModal, setShowPermissionViewModal] = useState(NaN);
    const [nP, setNP] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [date, setDate] = useState<Date | undefined>();
    const [companyFilter, setCompanyFilter] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState<number[]>([]);

    const [companyList, setCompanyList] = useState<{
        value: string;
        label: string;
    }[]>([]);

    const statusAvail = useMemo(() => {
        return [
            { value: 0, label: t('Orders status pending') },
            { value: 1, label: t('Orders status granted') },
            { value: 2, label: t('Rejected') },
        ]
    }, [t]);

    useEffect(() => {
        if (xlsData) {
            saveAs(xlsData, 'permissionlist.xls');
        }
    }, [xlsData]);

    useEffect(() => {
        setIsFetched(true);
        refetch({ offset: current, limit: PAGE_SIZE, ordering, search, provider_name: (isProvider ? '' : companyFilter.join(',')), consumer_name: (isProvider ? companyFilter.join(',') : ''), status: statusFilter.join(','), crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '' })
            .then(() => setIsFetched(false))
            .catch(() => setIsFetched(false));
    }, [current, date, ordering, refetch, companyFilter, search, statusFilter]);

    useEffect(() => {
        setHasMore(!!data?.results.next);
    }, [data?.results]);

    const handleNavigation = useCallback(() => {
        if ((document.body.scrollHeight - window.innerHeight) - window.scrollY < 300 && !isFetched) {
            if (hasMore) {
                setCurrent(data?.results.data.length || 0);
            }
        }
    }, [data?.results.data.length, hasMore, isFetched]);

    useEffect(() => {
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        refetch({ offset: current, limit: PAGE_SIZE, ordering, search, status: statusFilter.join(','), crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '' });
        refetchOrders({
            offset: 0,
            limit: 0
        });
    }, []);

    useEffect(() => {
        if (clients?.results.data) {
            setCompanyList(clients.results.data.map((c) => ({ value: c.name, label: c.name })));
        }
        // const uniqueCompany = new Set<string>();
        // clients?.results.data.every(s => uniqueCompany.add(s.name));
        // const result: { value: string, label: string }[] = [];
        // uniqueCompany.forEach((c) => result.push({ value: c, label: c }));
        // setCompanyList(result);
    }, [clients]);

    useEffect(() => {
        dataOrders?.results.count && setNP(dataOrders?.results.count);
    }, [dataOrders]);

    return (<Content>
        <ContentShadow />
        <PermissionModalModal isOpen={showPermissionModal} onClose={() => setShowPermissionModal(false)} />
        <PermissionViewModal isOpen={!isNaN(showPermissionViewModal)} permId={showPermissionViewModal} onClose={() => setShowPermissionViewModal(NaN)} />
        <MainHeader label={t('Permissions')} icon={<HeadIcon />}>
            <span className="main__header-item">
                <Link to="/access" className="link-accent">
                    <Trans>Orders</Trans>
                </Link> {!!nP && `· ${nP}`}
            </span>
        </MainHeader>

        <div className="main__content-filter">
            <div className="main__controls _no-sort">
                <div className="main__controls-filter">
                    <Searcher value={search} onChange={(v) => setSearch(v)} />
                    <div className="main__controls-filter-item">
                        <DatePicker value={date} placeholder={t('Orders filter date')} setValue={(v) => setDate(v)} />
                    </div>
                    <MultiSelect className="main__controls-filter-item  _wide" placeholder={t('Recipient')}
                        value={companyFilter} options={companyList || []} setValue={(v) => setCompanyFilter(v)} minimized />
                    <MultiSelect className="main__controls-filter-item  _wide" placeholder={t('Status')}
                        value={statusFilter} options={statusAvail} setValue={(v) => setStatusFilter(v)} minimized />
                    <a href="#xls" className="main__types-file" target="_blank" onClick={(e) => {
                        e.preventDefault();
                        fetchXlsData('/be/api/content/access/permissions/xlsx/');
                    }}>
                        <FileDoc />
                        <Trans>Orders xls export</Trans>
                    </a>
                </div>
                {/* <div className="main__controls-filter-trigger" onClick={() => setFilterVisible(!filterVisible)}>
                    <Filter />
                    {!!filterCount && <span className="main__controls-filter-trigger-counter">{filterCount}</span>}
                </div> */}
            </div>
        </div>

        <Table className="permissions-list">
            <TableHeader>
                <TableHeaderColumn header="ID" field="id" className="_id" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn header={t('order:PERMISSION_RECIPIENT')} field="provider_name" className="_content" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn header={t('order:PERMISSION_PERIOD_START')} className="_date" />
                <TableHeaderColumn header={t('order:PERMISSION_PERIOD_END')} className="_date" />
                <TableHeaderColumn header={t('Status')} field="status" className="_content" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn className="_arrow" header={''} />
            </TableHeader>
            <TableBody>
                {data?.results.data?.map((perm) => (
                    <PermissionTableRow key={perm.id} perm={perm} isProvider={isProvider} onShowPermissionView={(pid) => setShowPermissionViewModal(pid)} />
                ))}
            </TableBody>
        </Table>
        {isProvider &&
            <CreateButton onClick={() => navigate('/access/permissions/create')/*setShowPermissionModal(true)*/} />
        }
    </Content>)
}

export default PermissionsPage;
