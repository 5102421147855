import { format } from 'date-fns';
import saveAs from 'file-saver';
import { ReactNode, useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as TV } from '../../assets/img/icons/tv.svg';
import { ReactComponent as FileDoc } from '../../assets/svg/file-doc.svg';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import { ReactComponent as HeadIcon } from '../../assets/svg/PermRequest.svg';
import { ACCOUNT_TYPES, ORDER_STATUSES, SOURCE_MAP } from '../../const';
import { useAuth } from '../../hooks';
import NoCover from '../../img/no-cover.svg';
import { AVAIL_SOURCES } from '../../pages/PermissionsPage';
import { toMplatDate } from '../../pages/ToolsPage';
import { useGetXlsMutation } from '../../services/api';
import {
    AccessFull,
    AccessPermissionFull,
    AccessPermissionStatus,
    AccessResponse,
    ContentType,
    LicenseAgreement,
    useGetMetaDataMutation,
    useLazyGetAccessPermissionPdfQuery,
    useLazyGetAccessPermissionQuery,
    useLazyGetAccessPermissionsQuery,
    useLazyGetAccessRequestQuery,
    useLazyGetAccessRequestsQuery,
    useLazyGetContentUnitMediaQuery
} from '../../services/content_v1';
import { AccessRequestStatus, useGetAccountListQuery } from '../../services/users_v1';
import { extractErrorDetail, useIsAdmin, useIsConsumer } from '../common';
import Button from './Button/Button';
import { CheckBox } from './CheckBox';
import Content from './Content';
import ContentShadow from './ContentShadow';
import Table from './DataTable/Table';
import TableBody from './DataTable/TableBody';
import { TableColumn } from './DataTable/TableColumn';
import TableHeader from './DataTable/TableHeader';
import { TableHeaderColumn } from './DataTable/TableHeaderColumn';
import TableListName from './DataTable/TableListName';
import TableRow from './DataTable/TableRow';
import { DatePicker } from './DatePicker';
import ImageLoader from './ImageLoader';
import MainHeader from './MainHeader';
import { MobileDateFilterItem } from './MobileDateFilterItem';
import MobileFilter from './MobileFilter';
import { Modal } from './Modal';
import { ErrorModal } from './Modals/ErrorModal';
import MultiSelect from './MultiSelect';
import NoHideEmailAdmin from './NoHide/NoHideEmailAdmin';
import NoHideFIOAdmin from './NoHide/NoHideFIOAdmin';
import NoHidePhoneAdmin from './NoHide/NoHidePhoneAdmin';
import { Searcher } from './Searcher';
import { SelectButton } from './SelectButtonSpan';
import { Spinner } from './Spinner';


function getOrderDate(order: AccessResponse) {
    if (order.added) {
        const timeStr = order.added;
        const dt = Date.parse(timeStr);
        // 14.05.2022 · 18:43
        return format(dt, 'dd.MM.yyyy · HH:mm');
    } else
        return '';
}

// function getOrderDateForSort(order: AccessResponse) {
//     if (order.added) {
//         return order.added;
//     } else
//         return '';
// }

export function toSourceParams(rawParams: string | undefined): ReactNode {
    if (rawParams) {
        const params = rawParams?.split('|') || [''];
        if (params.length > 1) {
            return (<>
                {params[0]}
                <br />
                {params[1]}
            </>);
        } else {
            return params[0];
        }
    }
    return '';
}

export interface OrdersTableRowProps {
    order: AccessResponse,
    onShowPermission: (id: number) => void
    onShowOrder: (id: number, accId: number) => void
}

function OrdersTableRow({ order, onShowPermission, onShowOrder }: OrdersTableRowProps) {
    const isConsumer = useIsConsumer();
    const isAdmin = useIsAdmin();
    const [mediafilesRequest] = useLazyGetContentUnitMediaQuery();
    const [getMetaData] = useGetMetaDataMutation();
    // const [getHeader] = useGetMediaHeaderMutation();
    const firstUnit = order.units.at(0);
    const coverPath = firstUnit && firstUnit.covers.length > 0 ? firstUnit.covers[0] : '';
    const disabled = order.status === AccessRequestStatus.REJECTED;
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [errorTitle, setErrorTitle] = useState<string>('');

    function onDownload(unitId: number, title: string) {
        mediafilesRequest(Number(unitId)).unwrap().then((/*data*/) => {
            getMetaData({ id: unitId as number }).unwrap()
                .then((metaData) => {
                    const filename = title;
                    saveAs(metaData, `${filename}.zip`);
                })
                .catch(e => {
                    setErrorTitle(t('Metadata download error'));
                    setError(extractErrorDetail(e));
                })

            // if (!!data.results.mediafiles?.length) {
            //     data.results.mediafiles?.forEach(f => {
            //         getHeader(BASE_URL.substring(0, BASE_URL.length - 1) + f.url).then(() => {
            //             window.open(BASE_URL.substring(0, BASE_URL.length - 1) + f.url);
            //         });
            //     });
            // }
        }).catch((e) => {
            setErrorTitle(t('Get mediafiles error'));
            setError(extractErrorDetail(e));
        });
    }

    return (
        <TableRow key={order.id} className={`${disabled && 'disabled'}`}>
            <ErrorModal isOpen={!!error} onClose={() => setError('')} content={error} title={errorTitle} />

            <TableColumn className="_id">{order.id}</TableColumn>
            <TableColumn className="_status">
                <span className="orders-list__status-label">{t(ORDER_STATUSES[order.status])}</span>
            </TableColumn>
            {isAdmin ?
                <TableColumn className="_subscriber">
                    <div className="orders-list__subscriber">
                        {order.recipient.logo &&
                            <ImageLoader url={order.recipient.logo} className="orders-list__subscriber-logo" />
                        }
                        <strong className="orders-list__subscriber-name">
                            {order.is_favorites &&
                                <span className="catalog-card__controls-status _min _vip"
                                    title={t('account:VIP_ACCESS')}>VIP
                                </span>
                            }
                            {order.recipient.name}
                        </strong>

                        <span className="orders-list__subscriber-rep">
                            <NoHideFIOAdmin user={order.recipient.owner} />
                        </span>
                        {' · '}
                        <NoHidePhoneAdmin user={order.recipient.owner} />
                        {' · '}
                        <NoHideEmailAdmin user={order.recipient.owner} />
                        <div className="orders-list__subscriber-cta">
                            {order.status === AccessRequestStatus.GRANTED &&
                                <a href="#download" className="btn _min" onClick={() => onDownload(order.units.at(0)?.id || NaN, order.units.at(0)?.title || '')}>
                                    <Trans>Download metadata</Trans>
                                </a>
                            }
                            {[AccessRequestStatus.OPEN, AccessRequestStatus.PENDING].includes(order.status) && order.recipient.owner.email &&
                                <a href={`mailto:${order.recipient.owner.email}`}
                                    className="btn _min _bd">{t('Orders operation write')}</a>
                            }
                        </div>
                    </div>
                </TableColumn>
                :
                <TableColumn className="_subscriber">
                    <div className="orders-list__subscriber">
                        {order.provider.logo &&
                            <ImageLoader url={order.provider.logo} className="orders-list__subscriber-logo" />
                        }
                        <strong className="orders-list__subscriber-name">
                            {order.is_favorites &&
                                <span className="catalog-card__controls-status _min _vip"
                                    title={t('account:VIP_ACCESS')}>VIP
                                </span>
                            }
                            {order.provider.name}
                        </strong>

                        <span className="orders-list__subscriber-rep">
                            <NoHideFIOAdmin user={order.provider.owner} />
                        </span>
                        {' · '}
                        <NoHidePhoneAdmin user={order.provider.owner} />
                        {' · '}
                        <NoHideEmailAdmin user={order.provider.owner} />
                        <div className="orders-list__subscriber-cta">
                            {order.status === AccessRequestStatus.GRANTED &&
                                <a href="#download" className="btn _min" onClick={() => onDownload(order.units.at(0)?.id || NaN, order.units.at(0)?.title || '')}>
                                    <Trans>Download metadata</Trans>
                                </a>
                            }
                            {[AccessRequestStatus.OPEN, AccessRequestStatus.PENDING].includes(order.status) && order.provider.owner.email &&
                                <a href={`mailto:${order.provider.owner.email}`}
                                    className="btn _min _bd">{t('Orders operation write')}</a>
                            }
                        </div>
                    </div>
                </TableColumn>
            }
            {isAdmin && <TableColumn className="_subscriber">
                <div className="orders-list__subscriber">
                    {order.consumer.logo &&
                        <ImageLoader url={order.consumer.logo} className="orders-list__subscriber-logo" />
                    }
                    <strong className="orders-list__subscriber-name">
                        {order.is_favorites &&
                            <span className="catalog-card__controls-status _min _vip"
                                title={t('account:VIP_ACCESS')}>VIP
                            </span>
                        }
                        {order.consumer.name}
                    </strong>

                    <span className="orders-list__subscriber-rep">
                        <NoHideFIOAdmin user={order.consumer.owner} />
                    </span>
                    {' · '}
                    <NoHidePhoneAdmin user={order.consumer.owner} />
                    {' · '}
                    <NoHideEmailAdmin user={order.consumer.owner} />
                </div>
            </TableColumn>}
            <TableColumn className="_content">
                {order.units.at(0)?.ctype === ContentType.UNIT ?
                    <TableListName url={coverPath} link={`/content/${order.units.at(0)?.ctype === ContentType.TV ? 'tv' : 'media'}/${order.units.at(0)?.id || NaN}/`} title={order.units.at(0)?.title} />
                    :
                    <div className={`orders__content ${isConsumer && '_user'}`}>
                        <span className="orders__content-tv link link-accent" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowOrder(order.id, order.account_id);
                        }} style={{ stroke: 'black' }}>
                            <TV />&nbsp;<Trans>order:TV_COUNT</Trans>: {order.units.length}
                        </span>
                        <span className="orders__content-status">
                            <span className="orders__content-status">
                                {[AccessRequestStatus.GRANTED].includes(order.status) && order.permissions.at(0)?.id &&
                                    <span className="link link-accent" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowPermission(order.permissions.at(0)?.id || NaN);
                                    }}>
                                        <Trans>Permission</Trans>
                                    </span>
                                }
                            </span>
                        </span>
                    </div>
                }
            </TableColumn>
            <TableColumn className="_date">{getOrderDate(order)}</TableColumn>
        </TableRow>
    )
}

// function addSortProperties(order: AccessResponse) {
//     return {
//         ...order,
//         sort_id: order.id,
//         sort_status: ORDER_STATUSES[order.status],
//         sort_subscriber: order.provider?.name,
//         sort_content: order.units.map((u) => u.title).join(', '),
//         sort_date: getOrderDateForSort(order)
//     }
// }

// function dynamicSort(property: string) {
//     let sortOrder = 1;
//     if (property[0] === '-') {
//         sortOrder = -1;
//         property = property.substr(1);
//     }
//     property = 'sort_' + property;

//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     return function (a: any, b: any) {
//         const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//         return result * sortOrder;
//     }
// }

interface OrderModalProps {
    isOpen: boolean,
    orderId: number,
    accId: number,
    onClose: () => void,
}

function OrderModal({ isOpen, orderId, accId, onClose }: OrderModalProps) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [refetch, { data: accessData }] = useLazyGetAccessRequestQuery();

    const [access, setAccess] = useState<AccessFull | undefined>(undefined);

    useEffect(() => {
        if (!isNaN(orderId)) {
            refetch({ ar_id: orderId, acc_id: accId || auth.account });
        }
    }, [auth, orderId]);

    useEffect(() => {
        if (accessData?.results) {
            setAccess(accessData?.results);
        }
    }, [accessData]);

    return (
        <Modal className={'order__permission-popup'} isOpen={isOpen} onClose={() => onClose()}>
            {access && (
                <div className="order__permission">
                    <div className="user-settings__labels">
                        <span className="user-settings__labels-item">ID · {orderId}</span>
                        <span className="user-settings__labels-item">{toMplatDate(access.added, true)}</span>
                    </div>
                    <h2 className="popup__heading"><Trans>order:ORDER_REQ</Trans>:</h2>
                    <div className="tv-content__order-list">
                        {access.units.map((unit) => <div key={unit.id} className="tv-content__order-list-item">
                            {unit.covers.length > 0 ? <ImageLoader url={unit.covers[0]} /> : <img src={NoCover} alt="" />}
                            <span className="tv-content__order-list-item-name">{unit.title}</span>
                            <span className="tv-content__order-list-item-theme">{unit.category?.name}</span>
                        </div>)}
                    </div>

                    <h3><Trans>order:HAVE_RIGHTS</Trans></h3>
                    <p>
                        {access.details.licagr === LicenseAgreement.NO && t('No')}
                        {access.details.licagr === LicenseAgreement.YES && t('Yes')}
                        {access.details.licagr === LicenseAgreement.PARTIAL && t('order:ORDER_LIC_PARTIAL')}
                    </p>

                    <h3><Trans>order:ORDER_REQ_WHO</Trans></h3>
                    <p><Trans>{ACCOUNT_TYPES[access.owner.rtype || 0]}</Trans></p>
                    <p>
                        <Link to={`/accounts/view/${access.provider.id}`} className="link-accent">
                            <NoHideFIOAdmin user={access.owner.owner} />
                        </Link>, <br />
                        <NoHidePhoneAdmin user={access.owner.owner} />,
                        <br />
                        <NoHideEmailAdmin user={access.owner.owner} />
                    </p>

                    <h3>
                        <Trans>
                            order:ORDER_ADMIN
                        </Trans>
                    </h3>
                    <p>
                        <Link to={`/accounts/view/${access.provider.id}`} className="link-accent">
                            <NoHideFIOAdmin user={access.provider.owner} />
                        </Link>, <br />
                        <NoHidePhoneAdmin user={access.owner.owner} />,
                        <br />
                        <NoHideEmailAdmin user={access.owner.owner} />
                    </p>

                    <h3>
                        <Trans>order:ORDER_WISHES</Trans>
                    </h3>
                    <p>
                        <span className="user-settings__labels-item">{AVAIL_SOURCES.find((s) => s.value === access.details.pstype)?.label}</span>
                        {' '}
                        {access.details.psdetails && <span className="user-settings__labels-item">{access.details.psdetails}</span>}
                    </p>

                    <h3><Trans>order:REQ_TEST_PERIOD</Trans></h3>
                    <p>{access.details.ontest ? t('Yes') : t('No')}</p>
                </div>
            )}
        </Modal >
    )
}

interface PermissionCreateModalProps {
    isOpen: boolean,
    permId: number,
    onClose: () => void,
}

export function PermissionModalModal({ isOpen, permId, onClose }: PermissionCreateModalProps) {
    const { t } = useTranslation();

    const [fetchPermission, { data: permData }] = useLazyGetAccessPermissionQuery();
    const [fetchPdf] = useLazyGetAccessPermissionPdfQuery();

    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<string>();

    const [perm, setPerm] = useState<AccessPermissionFull | undefined>(undefined);

    const successClass = 'popup_message';

    useEffect(() => {
        if (isOpen) {
            fetchPermission(permId);
            setSuccess(false);
            setFail('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (permData?.results) {
            setPerm(permData?.results);
        }
    }, [permData]);

    return (
        <Modal className={success ? successClass : 'form-opinion'} isOpen={isOpen} onClose={() => onClose()}>
            {fail}
            {perm &&
                <div className="permission__channels-view">
                    {perm.status === AccessPermissionStatus.REVOKED &&
                        <p>
                            <span style={{ color: 'red' }}><Trans>Permission</Trans> ID{perm.id} <Trans>REVOKED</Trans> {perm.revoked && toMplatDate(perm.revoked, true)}</span>
                        </p>
                    }
                    {perm.status === AccessPermissionStatus.ISSUED &&
                        <p>
                            <span style={{ color: 'green' }}><Trans>Permission</Trans> ID{perm.id} <Trans>ISSUED</Trans> {perm.issued && toMplatDate(perm.issued, true)}</span>
                        </p>
                    }
                    <p>
                        {perm.issuer_accs?.length ?
                            <>
                                <strong>
                                    {perm.issuer_accs.map((acc) => acc.org.name || acc.name).join(', ')}
                                </strong>
                                {' '}
                                <Trans>order:PERMISSION_FROM_MANY_TO</Trans>
                            </>
                            :
                            <>
                                <strong>{perm.issuer.org?.name || perm.issuer.name}</strong>
                                {' '}
                                <Trans>order:PERMISSION_FROM_TO</Trans>
                            </>
                        }
                        {/* <strong>{perm.issuer.org?.name || perm.issuer.name}</strong>,
                        {' '}
                        <Trans>order:PERMISSION_FROM_TO</Trans> */}
                        {' '}
                        <strong>{perm.recipient.org?.name || perm.recipient.name}</strong>
                        {' '}
                        <Trans>order:PERMISSION_FROM_TO_TV</Trans>
                    </p>
                    <div className="permission__channels-view-channels">
                        <table>
                            <thead>
                                <tr>
                                    <td className="_name"><Trans>TV channel</Trans></td>
                                    <td className="_format"><Trans>order:TV_QUALITY</Trans></td>
                                    <td className="_licence"><Trans>order:TV_MEDIA_LIC</Trans></td>
                                    <td className="_comment"><Trans>order:TV_VERSION</Trans></td>
                                </tr>
                            </thead>
                            <tbody>
                                {perm.units.map((unit) => <tr key={unit.unit.id}>
                                    <td className="_name">{unit.unit.title}</td>
                                    <td className="_format">{unit.fmt.map((f) => f.name).join(', ')}</td>
                                    <td className="_licence">{unit.unit.mmcertno} {unit.unit.licno && ` · ${unit.unit.licno}`}</td>
                                    <td className="_comment">{unit.version}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <h3><Trans>order:IN_SOURCES</Trans>:</h3>
                    <ul>
                        {perm.details.sources?.map((s) => <li key={s.stype}>
                            {SOURCE_MAP[s.stype]}
                            {s.satellites && <><br />{s.satellites.map((ss) => ss.name).join(', ')}</>}
                            <br />
                            {toSourceParams(s.params)}
                        </li>)}
                        {!perm.details.sources && <li><Trans>account:NOT_SPECIFIED</Trans></li>}
                    </ul>

                    {!!perm.details?.additional && <>
                        <h3><Trans>order:SPEC_CONDITIONS</Trans></h3>
                        <p>
                            {perm.details.additional}
                        </p>
                    </>}

                    {!!perm.details.licno && <>
                        <h3><Trans>order:LIC</Trans></h3>
                        <p>
                            {perm.details.licno} {perm.details.licdate ? `от ${perm.details.licdate}` : ''}
                        </p>
                    </>}

                    <h3><Trans>order:PERMISSION_PERIOD</Trans></h3>
                    <p>
                        <Trans>order:PERMISSION_PERIOD_FROM</Trans> {perm.validity.lower ? toMplatDate(perm.validity.lower, true) : t('order:PERMISSION_PERIOD_FROM_TODAY')}
                        &nbsp;
                        <Trans>order:PERMISSION_PERIOD_TO</Trans> {perm.validity.upper ? toMplatDate(perm.validity.upper, true) : t('order:PERMISSION_PERIOD_TO_REVOKE')}
                    </p>

                    <h3><Trans>order:PERMISSION_ISSUER</Trans></h3>
                    <p>
                        <Link to={`/accounts/view/${perm.issuer.id}`} className="link-accent">
                            <NoHideFIOAdmin user={perm.issuerperson} />
                        </Link>, <br />
                        <NoHidePhoneAdmin user={perm.issuerperson} />
                        ,<br />
                        <NoHideEmailAdmin user={perm.issuerperson} />
                    </p>

                    <p className="permission__channels-view-cta">
                        <Button onClick={(ev) => {
                            ev.preventDefault();
                            fetchPdf(perm.id).unwrap().then((data) => {
                                saveAs(data, `permission_${perm.id}.pdf`);
                            }).catch((err) => {
                                alert(extractErrorDetail(err));
                            });
                        }}>
                            <Trans>order:LOAD_PDF</Trans>
                        </Button>
                        {/* <a href={`${BASE_URL.substring(0, BASE_URL.length - 1)}/access/permissions/${perm.id}/pdf/`} target="_blank">
                            <Button><Trans>order:LOAD_PDF</Trans></Button>
                        </a> */}
                    </p>
                </div>
            }
        </Modal>
    );
}

function OrderContentConsumer() {
    // const
    const PAGE_SIZE = 24;
    // hooks
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const auth = useAuth();
    const isAdmin = useIsAdmin();
    // fetches
    const [trigger, { data: xlsData }] = useGetXlsMutation();
    const [refetch, { data }] = useLazyGetAccessRequestsQuery();
    const [refetchPermissions, { data: permissionsData }] = useLazyGetAccessPermissionsQuery();
    const { data: clients } = useGetAccountListQuery({});
    // states
    const [current, setCurrent] = useState(0);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const [permIdDialog, setPermIdDialog] = useState(NaN);
    const [orderIdDialog, setOrderIdDialog] = useState(NaN);
    const [accIdDialog, setAccIdDialog] = useState(NaN);

    const [search, setSearch] = useState<string>('');
    const deferredSearch = useDeferredValue(search);
    const [date, setDate] = useState<Date | undefined>();
    const [companyFilter, setCompanyFilter] = useState<string[]>([]);
    const [ctypeFilter, setCTypeFilter] = useState<number[]>([]);
    const [cTypeTVFilter, setCTypeTVFilter] = useState<boolean>(false);
    const [cTypeContentFilter, setCTypeContentFilter] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [mainFilter, setMainFilter] = useState<any>('all');
    const [ordering, setOrdering] = useState('-id');

    const [filterVisible, setFilterVisible] = useState(false);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [nP, setNP] = useState(0);

    const mainFilterItems = useMemo(() => [
        { value: 'all', label: t('Orders filter all'), counter: data?.results.totals['all'] },
        { value: AccessRequestStatus.OPEN, label: t('Orders filter new'), counter: data?.results.totals['opened'] },
        { value: AccessRequestStatus.PENDING, label: t('Orders filter pending'), counter: data?.results.totals['pending'] },
        { value: AccessRequestStatus.GRANTED, label: t('Orders filter granted'), counter: data?.results.totals['granted'] },
        { value: AccessRequestStatus.REJECTED, label: t('Orders filter rejected'), counter: data?.results.totals['rejected'] },
    ], [data?.results, t]);

    const [companyList, setCompanyList] = useState<{
        value: string;
        label: string;
    }[]>([]);

    // effects
    useEffect(() => {
        setCurrent(0);
    }, [companyFilter, date, mainFilter, ordering]);

    useEffect(() => {
        const tmp: number[] = [];
        if (cTypeContentFilter) tmp.push(ContentType.UNIT);
        if (cTypeTVFilter) tmp.push(ContentType.TV);
        setCTypeFilter(tmp);
    }, [cTypeTVFilter, cTypeContentFilter]);

    useEffect(() => {
        permissionsData?.results.count && setNP(permissionsData?.results.count);
    }, [permissionsData]);

    useEffect(() => {
        setIsFetched(true);
        refetchPermissions({ offset: 0, limit: 0 });
        const sender = companyFilter.join(',');
        const effectiveStatus = mainFilter === 'all' ? '' : (mainFilter === AccessRequestStatus.ARCHIVED ? '2&status=1' : mainFilter);
        refetch({
            offset: current,
            limit: PAGE_SIZE,
            ordering,
            search: deferredSearch,
            artype: ctypeFilter.join(','),
            provider_name: sender,
            status: effectiveStatus,
            crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '',
            u: auth.user + auth.account,
        })
            .then(() => setIsFetched(false))
            .catch(() => setIsFetched(false));
    }, [current, date, deferredSearch, ordering, refetch, companyFilter, ctypeFilter, mainFilter, auth.account, auth.user]);

    useEffect(() => {
        setHasMore(!!data?.results.next);
    }, [data?.results]);

    const handleNavigation = useCallback(() => {
        if ((document.body.scrollHeight - window.innerHeight) - window.scrollY < 300 && !isFetched) {
            if (hasMore) {
                setCurrent(data?.results.data.length || 0);
            }
        }
    }, [data?.results.data.length, hasMore, isFetched]);

    useEffect(() => {
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        const tmp: Record<string, string> = {};
        searchParams.forEach((v, k) => {
            tmp[k] = v;
        });
        if (tmp.status) {
            setMainFilter(tmp.status);
        }
    }, [searchParams]);

    useEffect(() => {
        if (xlsData) {
            saveAs(xlsData, 'orderlist.xls');
        }
    }, [xlsData]);

    useEffect(() => {
        const uniqueCompany = new Set<string>();
        clients?.results.data.every(s => uniqueCompany.add(s.name));
        const result: { value: string, label: string }[] = [];
        uniqueCompany.forEach((c) => result.push({ value: c, label: c }));
        setCompanyList(result);
    }, [clients]);

    useEffect(() => {
        setFilterCount(
            companyFilter.length + (date ? 1 : 0)
        )
    }, [date, companyFilter.length]);

    return (<Content>
        <ContentShadow />
        <OrderModal isOpen={!isNaN(orderIdDialog)} orderId={orderIdDialog} accId={accIdDialog} onClose={() => { setOrderIdDialog(NaN); setAccIdDialog(NaN); }} />
        <PermissionModalModal isOpen={!isNaN(permIdDialog)} permId={permIdDialog} onClose={() => setPermIdDialog(NaN)} />
        <MainHeader label={t('Orders')} icon={<HeadIcon />}>
            <span className="main__header-item">
                <Link to="/access/permissions" className="link-accent">
                    <Trans>Permissions</Trans>
                </Link> {!!nP && `· ${nP}`}
            </span>
        </MainHeader>

        <div className="main__content-filter">
            <SelectButton value={mainFilter} items={mainFilterItems} onChange={(v) => setMainFilter(v)}>
                <a href="#xls" className="main__types-file" target="_blank" onClick={(e) => {
                    e.preventDefault();
                    trigger('/be/api/content/access/requests/xlsx/');
                }}>
                    <FileDoc />
                    <Trans>Orders xls export</Trans>
                </a>
            </SelectButton>

            <div className="main__controls _no-sort">
                <div className="main__controls-filter">
                    <Searcher value={search} onChange={(v) => setSearch(v)} />
                    <div className="main__controls-filter-item">
                        <DatePicker value={date} placeholder={t('Orders filter date')} setValue={(v) => setDate(v)} />
                    </div>
                    <MultiSelect className="main__controls-filter-item" placeholder={t('Orders filter producer')}
                        value={companyFilter} options={companyList || []} setValue={(v) => setCompanyFilter(v)} minimized />
                    {/* <MultiSelect className="main__controls-filter-item" placeholder={'Тип контента'} */}
                    {/* value={ctypeFilter} options={availCTypes} setValue={(v) => setCTypeFilter(v)} minimized /> */}
                    <div className="main__controls-filter-item _pre-checkbox">
                        <CheckBox value={cTypeTVFilter} label={t('account:SPEC_TV')} onChange={(v) => setCTypeTVFilter(v)} />
                    </div>
                    <div className="main__controls-filter-item">
                        <CheckBox value={cTypeContentFilter} label={t('account:SPEC_CONTENT')} onChange={(v) => setCTypeContentFilter(v)} />
                    </div>
                </div>
                <div className="main__controls-filter-trigger" onClick={() => setFilterVisible(!filterVisible)}>
                    <Filter />
                    {!!filterCount && <span className="main__controls-filter-trigger-counter">{filterCount}</span>}
                </div>
            </div>
        </div>

        <Table className="orders-list">
            <TableHeader>
                <TableHeaderColumn header="ID" field="id" className="_id" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn header={t('Orders table status')} field="status" className="_status" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn header={t('Orders table producer')} field="provider_name" className="_subscriber" ordering={ordering} setOrdering={setOrdering} />
                {isAdmin && <TableHeaderColumn header={t('Orders table consumer')} field="consumer_name" className="_subscriber" ordering={ordering} setOrdering={setOrdering} />}
                <TableHeaderColumn header={t('Orders table content')} field="title" className="_content" ordering={ordering} setOrdering={setOrdering} />
                <TableHeaderColumn header={t('Orders table date')} field="added" className="_date" ordering={ordering} setOrdering={setOrdering} />
            </TableHeader>
            <TableBody>
                {data?.results.data.map(order => (
                    <OrdersTableRow key={`${order.id}+${order.account_id}`} order={order} onShowPermission={(permId) => setPermIdDialog(permId)} onShowOrder={(orderId, accId) => { setOrderIdDialog(orderId); setAccIdDialog(accId) }} />
                ))}
            </TableBody>
            {isFetched && <Spinner />}
        </Table>

        <MobileFilter
            filterVisible={filterVisible}
            setFilterVisible={setFilterVisible}
            onClean={() => {
                setCompanyFilter([]);
                setDate(undefined);
            }}
        >
            <MobileDateFilterItem value={date} label={t('Orders filter date')}
                placeholder={t('Orders filter date placeholder')}
                setValue={(v) => setDate(v)} />
            {/* <MobileFilterItem label={t('Orders filter producer')} */}
            {/* value={companyFilter} options={companyList || []} setValue={(v) => setCompanyFilter(v)} /> */}
        </MobileFilter>

    </Content>)
}

export default OrderContentConsumer;
